<!-- Here added the div block two times to handle the drag functionality. Except the proximity brick all other
     bricks drag from the top only (SM-12071)-->
<!-- Add the ng template to render the content (SM-12071) -->
<ng-template #content><ng-content></ng-content></ng-template>
<ng-container *ngIf="title!='Proximity'">
  <div ngDraggable [handle]="myHandle" [bounds]="bodyEle" >  
    <div #myHandle class="modal-header switchBRICmodal-header{{alternateDesign}}"
      [style.backgroundColor]="titleBackgroundColor">
      <h3 #myHandle class="modal-title">{{title}}</h3>
      <button type="button" [hidden]="!showCloseButton" id="{{titleBackgroundColor}}_close" class="modal_close" aria-label="Close" (click)="close('dismiss')">x</button>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div class="modal-footer">
      <span *ngIf="footerMessage && isButtonHideUi" style="color: red;">
        {{footerMessage}}
      </span>
      <span [hidden]="isButtonHideUi">
        <button type="button" id="{{titleBackgroundColor}}_save" class="btn btn-primary" [disabled]="readOnlyModal" (click)="save()">{{saveButton}}</button>
        <button *ngIf="isIgnoreButtonEnabled" type="button" id="{{titleBackgroundColor}}_ignore" class="btn btn-primary" [disabled]="readOnlyModal" (click)="close('cancel')">{{ignoreButton}}</button>
  
        <button *ngIf="isClearAllButtonEnabled" type="button" id="{{titleBackgroundColor}}_clear" class="btn btn-primary clear-all-button" [disabled]="readOnlyModal" (click)="clearAll()">{{clearAllButton}}</button>
      </span>
      <ng-content select="[additionalFooter]"></ng-content>
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="title=='Proximity'">
  <div ngDraggable [bounds]="bodyEle"> 
    <!-- Removed the Handle to drag the modal from anywhere -->
    <div class="modal-header switchBRICmodal-header{{alternateDesign}}"
      [style.backgroundColor]="titleBackgroundColor">
      <h3  class="modal-title">{{title}}</h3>
      <button type="button" [hidden]="!showCloseButton" id="{{titleBackgroundColor}}_close" class="modal_close" aria-label="Close" (click)="close('dismiss')">x</button>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div class="modal-footer">
      <span *ngIf="footerMessage && isButtonHideUi" style="color: red;">
        {{footerMessage}}
      </span>
      <span [hidden]="isButtonHideUi">
        <button type="button" id="{{titleBackgroundColor}}_save" class="btn btn-primary" [disabled]="readOnlyModal" (click)="save()">{{saveButton}}</button>
        <button *ngIf="isIgnoreButtonEnabled" type="button" id="{{titleBackgroundColor}}_ignore" class="btn btn-primary" [disabled]="readOnlyModal" (click)="close('cancel')">{{ignoreButton}}</button>
  
        <button *ngIf="isClearAllButtonEnabled" type="button" id="{{titleBackgroundColor}}_clear" class="btn btn-primary clear-all-button" [disabled]="readOnlyModal" (click)="clearAll()">{{clearAllButton}}</button>
      </span>
      <ng-content select="[additionalFooter]"></ng-content>
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</ng-container>


